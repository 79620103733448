.table-container {
    padding: 20px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }
  
  .custom-table td {
    vertical-align: middle;
  }