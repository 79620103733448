.createbeat{
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: var(--bg-color-p2);
    height: 80vh;
  
}

.createbeatbutton{
  
    text-align: center;
    padding-top: 4%;

}
.createvisittable{
    background-color: var(--bg-color-p1);
    color: black;
    font-size: 13px;
}

.input-styled::placeholder {
    color: gray;
  }
  .mt-4{
    margin-top: 4px;
  }
  .pr-12{
    padding-right: 12px;
  }
  .pl-12{
    padding-left: 12px;
  }