#modal .modalContent {
    background-color: 'white';
    width: '650px';
    height: '496px';
    border-radius: '8px';
    object-fit: "contain";
}
#modal .modalContent {
    background-color: red;
    object-fit: "contain";
    width: '400px !important';
    height: '296px !important';
    border-radius: '8px';
}

.modalTarget {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 27px;
    gap: 10px;
}

.modalTarget span {
    background-color: rgb(3, 146, 58);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.modalTarget div {
    color: white
}

.visit {
    width: 264px;
    height: 12px;
    background-color: var(--bg-color-p0);
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    text-decoration: none !important;
    font-size: 14px;

}

.linkto {
    text-decoration: none !important;
    color: inherit;
}

.visit {
    width: 260px;
    height: 12px;
    background: var(--bg-color-p0);
    color: white;
    border-radius: 20px;
    padding: 10px 20px;
    text-decoration: none !important;
    font-size: 14px;
}

.visitHome {
    display: flex;
    align-items: center;
}

.storeDetails {
    /* height: 74px; */
    /* margin: 20px; */
    font-size: 14px;
    border-radius: 17px;
    max-width: 650px;
    width: 100%;
    background: var(--bg-color-p2);
    padding: 16px;
    /* height: 100vh; */
}

.storeName {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 16px;
}

.storeIdText {
    font-size: 13px;
}

.beatIdText {
    font-size: 13px;
    padding-top: 0px;
}

.crownIcon {
    font-size: 22px;
}

.premiumText {
    font-size: 14px;
    font-weight: 600;
}

.storeAddText {
    margin-bottom: 6px;
    font-weight: normal;
}

.StoreAdd1 {
    font-size: 13px;
    padding-top: 0px;
    gap: 20px;
}

.addressLine {
    font-size: 12px;
    /* font-weight: bold; */
}

.operText {
    font-size: 11px;
}

.operTime {
    font-weight: bold;
    color: black;
    font-size: 11px;
}

.contDetails {
    margin-bottom: 4px;
    font-weight: normal;
}

.userText {
    font-size: 12px;
    font-weight: bold;
}

.contIcon {
    padding-right: 4px;
}

.contText {
    font-size: 12px;
    padding-top: 4px;
    font-weight: bold;
}

.inventoryDetails {
    height: 74px;
    margin: 20px;
    font-size: 14px;
    border-radius: 17px;
}

.orderDateText {
    font-size: 12px;
    padding-top: 8px;
}

.checkGreen {
    color: green;
    font-size: 20px;
}

.checkOrder {
    padding-right: 30px;
}

.activetag {
    background: white;
    color: green;
    padding: 2px 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    flex-direction: row;
}

.activetagC {
    background: white;
    color: green;
    padding: 2px 16px 4px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    gap: 11px;
    font-size: 11px;
    flex-direction: row;
    display: flex;
    justify-self: center;
    align-items: center;
}

.inActivetag {
    background: white;
    color: red;
    padding: 1px 9px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    flex-direction: row;
}

.activetag .blinker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: green;
    color: green
}

.blinker-inActive {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgb(211, 19, 19);
    color: rgb(211, 19, 19)
}

.shopaddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.line {
    width: 100%;
    height: 20px;
    background: var(--bg-color-p2);
}

.premiumtag {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--bg-color-p0);
    font-size: 12px;
}

.operthours {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.orderdet {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding-top: 10px;
}

.orderListing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding: 10px 20px;
}

.pastorderListing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    padding: 10px 0px;
}

.orderIdText {
    font-size: 14px;
    font-weight: bold;
}

.orderDate {
    font-size: 14px;
    padding-top: 4px;
}

.focusedInd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.checkIcon {
    font-size: 20px;
    color: green;
}

.orderSummaryCheckIcon {
    font-size: 14px;
    color: green;
    margin-left: 8px;
}

.closeIcon {
    font-size: 20px;
    color: red;
}

.focusedText {
    font-size: 13px;
}

.add-store-form-container {
    max-width: 600px;
    padding: 20px;
    background: var(--bg-color-p2);
}
.add-store-form-container .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
}

.discount-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
}

.discount-text {
    display: flex;
    flex-direction: column;
}

.cancel-button,
.save-button {
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.cancel-button {
    background-color: rgb(247, 65, 65);
}

.save-button {
    background-color: var(--bg-color-p0);
    color: #fff;
}

.cancel-button:hover,
.save-button:hover {
    background-color: #333;
}

.inventorytitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.updateInventory {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--bg-color-p0);
    font-size: 12px;
}

.inveContainer {
    background: var(--bg-color-p1);
    color: black;
    font-size: 11px;
    text-align: center;
}

.invenData {
    background: var(--bg-color-p2);
    font-size: 11px;
    text-align: center;
}

.invenDataId {
    background: var(--bg-color-p2);
    font-size: 11px;
    text-align: center;
    text-decoration: underline;
    padding-top: 2px;
}

.inventheading {
    font-size: "11px";
    font-weight: "bold";
    color: "red";
    text-decoration: "underline";
    padding: 10px 10px;
}

.inventUpdate {
    width: 90px;
    height: 60px;
    border-radius: 12px;
    border: 2px solid #ddd;
    box-shadow: rgba(184, 64, 64, 0.3) 0px 1px 2px 0px, rgba(240, 83, 11, 0.15) 0px 2px 6px 2px;
}

.invenText {
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.takeData {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
}

.takeOrder {
    margin: 20px 0px;
}

.takeOrder button {
    width: 100%;
}

.orderText {
    font-size: 12px;
    text-align: center;
    color: white;
}

.checkin {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    flex-direction: column;
}

.visitText {
    font-size: 12px;
    color: #585858;
    font-weight: 600;
}

.tcolor {
    color: black;
}

.outText {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 24px 7px 24px;
    background: var(--bg-color-p0);
    color: white;
    border-radius: 7px;
}

.profile-detail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visit-picture {
    overflow-y: auto;
}

.visit-picture .visit-video-cont {
    margin-top: 24px;
}

.visit-picture img {
    margin-top: 16px;
    width: 100%;
    height: 232px;
}

.discount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}

.ant-modal-root .ant-modal-wrap {
    z-index: 1000000;
}