body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.selection-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-color-p2);
  color: #fff;
  padding: 14px;
  gap: 67px;
}


.brand,
.category {
  flex: 1;
  text-align: center;
}

select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
}
.search {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
  font-size: 12px;
}
.searchContainer {
  width: 90%;
  font-size: 15px;
  margin-top: 10px;
}
table{
  margin-top: 0;
}
.orderContainer {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  background: var(--bg-color-p1);
  border-radius: 14px;
  padding: 10px 0px;
  font-size: 14px;
  width: 100%;
}
.orderContent {
  justify-content: space-between;
  padding: 0 10px 0 10px;
}
.orderList{
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
}
.title {
  display: flex;
  justify-content: space-around;
}
.cases {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 15px;
}
.casesValue {
  text-align: center;
}
.caseInput {
  width: 30px;
  height: 10px;
  margin-top: 15px;
  font-size: 12px;
  font-weight: bold;
  color: var(--bg-color-p0);
}
.orderMrp {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 14px;
  padding: 0 20px 10px 20px;
}
textarea:focus,
input:focus {
  outline: none;
}
.category {
  font-size: 12px;
}
#categorySelect {
  font-size: 12px;
  width: 115px;
}
#brandSelect {
  font-size: 12px;
  width: 115px;
}
.ordertitle {
  padding: 7px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.fbold {
  font-weight: bold;
}
input::placeholder {
  color: var(--bg-color-p0); 
}
.ordHeadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.schemes {
  background: var(--bg-color-p0);
  color: white;
  border-radius: 6px;
}
.Csbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  gap: 20px;
  height: 100vh;
}
.btnC {
  background: rgb(255, 255, 255);
  padding: 7px 14px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 7px;
}
.btnS {
  background: rgb(76, 76, 201);
  padding: 7px 14px;
  color: white;
  border-radius: 7px;
}
.active-focused{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
 
}
.order-content{
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.orderIdTxt{
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 16px;
}
