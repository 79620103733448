.searchBrand {
    width: 100%;
}

.brandText {
    font-size: 16px;
    padding: 10px 0 7px 0;
}

.brandBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}

.btnS {
    background: rgb(76, 76, 201);
    padding: 0 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.btnC {
    background: rgb(255, 255, 255);
    padding: 0px 12px;
    margin-right: 0;
}

.schemeBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 170px;
}