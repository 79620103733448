.sticky-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--bg-color-p0);
  color: white;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  gap: 6px;
}
.m-0{
  margin: 0px;
}
.fs-22{
  font-size: 22px;
}
.footerTxtColor{
  color: white;
}
footer {
  position: fixed;
  background: rgb(7, 13, 121);
  padding: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
}

@media (max-width: 768px) {
  .sticky-footer {
    font-size: 14px;
  }
}
