.ant-modal.collect-payment .title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.ant-modal.collect-payment .btn {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.ant-modal.collect-payment .btn .yes {
  font-weight: 500;
}