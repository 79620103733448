
.profile-container {
  padding: 20px;
  background-color: var(--bg-color-p2);
}

.profile-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.profile-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.profile-info {
  flex: 1;
  padding: 0px 40px;
  text-align: center;
  position: relative;
}

.profile-info h2 {
  margin: 0;
  font-size: 1.5rem;
}

.profile-info p {
  margin: 5px 0;
  font-size: 17px;
  color: #555;
}

.profile-details {
  background: var(--bg-color-p1);
  padding: 30px 20px;
  height: 40vh;
}

.detail {
  margin-bottom: 10px;
}

.detail p {
  margin: 0;
  font-size: 14px;
}
.field {
  font-weight: bold;
}
.tabs-details {
  margin-top: 20px;
  padding-right: 10px;
}
.tabs {
  padding-right: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: var(--bg-color-p0);
}
.attendance-details {
  background: white;
  height: 300px;
}
.attendanceTh th {
  background-color: rgb(219, 214, 214);
  color: black;
  font-size: 12px;
}
.attendanceTd td {
  font-size: 12px;
}
.last30 {
  display: flex;
  justify-content: end;
  font-size: 12px;
  color: black;
}
.last30text {
  padding: 4px 12px;
  border-radius: 20px;
  margin-bottom: 10px;
}
.prodeletebg{
  border: 1px solid pink;
  border-radius: 50%;
  font-weight: bold;
  padding: 4px;
}
.uploadImg{
  position: absolute;
  margin: 70px -20px;
  background-color: rgb(94, 143, 49);
  border: none;
  color: white;
  border-radius: 50%;
  font-weight: bold;
}
:where(.css-dev-only-do-not-override-yj8ld).ant-upload-wrapper .ant-upload-list{
  display: none;
}
/* button:hover{
  background-color: green;
  color: white!important;
} */
.proaddIcon{
  color: white;
  font-weight: bold;
  z-index: 999;
}
.profileRef{
  display: none;
}
.profileNameTxt{
  font-size: 17px;
  font-weight: bold;
  color: black;
  text-transform: capitalize;
}